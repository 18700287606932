import React, { useState } from "react";

const ReadMore = ({ children }) => {
    function getText(html) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }
    const text = getText(children);

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
            <p className="text">
                {isReadMore ? text.slice(0, 400) : text}
                <span
                    onClick={toggleReadMore}
                    className="read-or-hide"
                    style={{color: "green"}}
                    >
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
            );
};


export default ReadMore;
