import React from 'react';
import { Helmet }
from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import slider from './../assets/img/index-3-products/p1.jpg';
import slider2 from './../assets/img/index-3-products/p3.jpg';
import { useState, useEffect }
from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { useCartContext }
from './Context';
import { Outlet, Link, useParams, useNavigate }
from "react-router-dom";

const Orderfail = () => {

    const login = JSON.parse(localStorage.getItem('USER_INFO'));
    var navigate = useNavigate();

    useEffect(() => {
    if (!login) {
    navigate('/register');
    }
    }, [])
            return (
                    <>
<section className="text-center shadow section section-mins nnrr">
    <div className="row">
        <div className="col-lg-12 p-5 bg-failed">
            <div className="row m-0">
                <h6 className="text-whites text-left">
                    <i className="fa fa-times-circle-o mr-2" aria-hidden="true"></i> Sorry,  
            { login ? login.name : <div></div> }
                </h6>
            </div>
        </div>
        <div className="col-lg-12 p-0 mt-5 mb-5">
            <div className="row m-0">
                <div className="col-lg-3">

                </div>
                <div className="col-lg-6">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="message-box-f _success _failed">
                                <i className="fa fa-times-circle" aria-hidden="true"></i>
                                <h2> Your payment failed </h2>
                                <p>  Try again later </p> 

                            </div> 
                        </div> 
                    </div> 
                </div>
                <div className="col-lg-3">

                </div>
            </div>
        </div>
    </div>
</section>
</>
);
};

export default Orderfail;


