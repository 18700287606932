import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Weblayout from './pages/layouts/Weblayout';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import Home from './pages/Home';
import Shop from './pages/Shop';
import Ebook from './pages/Ebook';
import Login from './pages/Login';
import Register from './pages/Register';
import Buy from './pages/Buy';
import Ordersuccess from './pages/Ordersuccess';
import Orderfail from './pages/Orderfail';
import Search from './pages/Search';
import Shopid from './pages/Shopid';
import Mybook from './pages/dashboard/Mybook';
import Myaccount from './pages/dashboard/Myaccount';
import Readebook from './pages/dashboard/Readebook';
import { CartProvider } from './pages/Context';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <BrowserRouter>
            <CartProvider>
        
                <Routes>
                    <Route path="/" element={ < Weblayout / > }>
                        <Route index element={ < Home / > } />
                        <Route exact path="shop" element={ < Shop / > } />
                        <Route path="register" element={ < Register / > } />
                        <Route path="login" element={ < Login / > } />
                        <Route exact path="ebook/:id" element={ < Ebook / > } />
                        <Route exact path="buy/:id" element={ < Buy / > } />
                        <Route exact path="ebook-order-success/:id" element={ < Ordersuccess / > } />
                        <Route exact path="ebook-order-fail" element={ < Orderfail / > } />
                        <Route exact path="mybook" element={ < Mybook / > } />
                        <Route exact path="myaccount" element={ < Myaccount / > } />
                        <Route exact path="search" element={ < Search / > } />
                        <Route exact path="shop/:id" element={ < Shopid / > } />
                    </Route>
                    <Route exact path="mybook/:id" element={ < Readebook / > } />
                </Routes>
            </CartProvider>
            
        </BrowserRouter>
                                );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
                        reportWebVitals();
