import React from 'react';
import { Helmet }
from "react-helmet";
import $ from "./../../assets/ebook/js/jquery.js";
import slider from './../../assets/img/index-3-products/p1.jpg';
import slider2 from './../../assets/img/index-3-products/p3.jpg';
import emp from './../../assets/img/covers.svg';
import Loader from './../components/Loader';

import { useState, useEffect }
from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { useCartContext }
from './../Context';
import { Outlet, Link, useParams, useNavigate }
from "react-router-dom";

const Mybook = () => {

    const login = JSON.parse(localStorage.getItem('USER_INFO'));
    var navigate = useNavigate();
    const [book, setBook] = useState([]);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        axios.post("https://rank2top.com/api/my_ebook", {email: login.email, phone: login.phone})
                .then(response => {
                    setBook(response.data.data);
                    setLoader(false);
                })
                .catch(error => {
                    console.error(error);
                    setLoader(false);
                });
    }, []);
    useEffect(() => {
    if (!login) {
    navigate('/register');
    }
    }, [])
            return (
            <>
<section className="section-breadcu padding-large">
    <div className="row">
        <div className="col-md-12">
            <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to="/">
                    <span>Home</span>
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <span> Books</span>
                </li>
            </ul>
        </div>
    </div>
</section>
<section className="section-description py-5 bg-white p-5">
 {loader ? <Loader /> : "" }

        
            {
                    book
                    ?
                <div className="row">
                    {book.map(post => (
                            <div className="col-lg-2 col-xs-6 mb-5"> 
                        <span className="product-list-left pull-left">
                            <a href={ "../mybook/" + post.slug }>
                                <img alt="product image" className="product-list-primary-img" src={ post.img } /> 
                            </a>
                        </span> 
                        <a href={ "../mybook/" + post.slug }>
                            <span className="product-list-right ex-related pull-left">
                                <span className="product-list-name h4 black-color">{ post.title } </span>
                                
                            </span>
                        </a> 		
                    </div>
                            ))} 
                    </div>

                    :
                    <div className="row">
            <div className="col-lg-12 library-content empty book-list">
                <p>Welcome to Rank2Top! Your Library is currently empty.</p>
            </div>
            <div className="col-lg-12 img-101 text-center">
                <img src={ emp } />
            </div>
        </div>
        }
            
</section>
</>
);
};

export default Mybook;






