import React from 'react';
import { Helmet } from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import slider from './../assets/img/index-3-products/p1.jpg';
import slider2 from './../assets/img/index-3-products/p3.jpg';
import Rateview from './components/Rateview';
import Loader from './components/Loader';
import { useCartContext }
from './Context';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { Outlet, Link }
from "react-router-dom";

const Home = () => {
    const settings = {
        dots: false,
        lazyLoad: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
        {
        breakpoint: 1024,
                settings: {
                slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                }
        },
        {
        breakpoint: 600,
                settings: {
                slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                }
        },
        {
        breakpoint: 480,
                settings: {
                slidesToShow: 2,
                        slidesToScroll: 1
                }
        }
        ]
    };
    const {setPurchase} = useCartContext();
    const [loader, setLoader] = useState(true);
    const [books, setBooks] = useState([]);
    useEffect(() => {
    axios.get('https://rank2top.com/api/ebook_list')
            .then(response => {
            setBooks(response.data.data);
    setLoader(false);
            })
            .catch(error => {
            console.error(error);
    setLoader(false);
            });
    }, []);
            return (
                    <>
<section className="home section image-slider" id="home">
    <div className="home-slider text-center">
        <div className="swiper-wrapper">
            <div className="swiper-slide" style=
            {{ backgroundImage: 'url(./../../assets/img/slider/slide4.jpg)' }}>
                <h1>Upgrade Knowledge  <span className="hglight"></span></h1>
                <h2 className="home-slider-title-main">80% Discount for this season </h2>
                <div className="home-buttons text-center">
                    <a href="#products" className="btn btn-lg  btn-primary">our ebooks</a>
                </div>
                <a className="arrow bounce text-center" href="#about">
                    <span className="ti-mouse"></span>
                    <span className="ti-angle-double-down"></span>
                </a>
            </div>
            <div className="swiper-slide" style={{ backgroundImage: 'url(./../../assets/img/slider/slide3.jpg)' }}>
                <h1>stay healthy </h1>
                <h2 className="home-slider-title-main">with working cart & pay pal</h2>
                <div className="home-buttons text-center">
                    <a href="#products" className="btn btn-lg  btn-primary">our products</a>
                </div>
                <a className="arrow bounce text-center" href="#about">
                    <span className="ti-mouse"></span>
                    <span className="ti-angle-double-down"></span>
                </a>
            </div>
        </div>
        <div className="home-pagination"></div>
        <div className="home-slider-next right-arrow-negative">
            <span className="ti-arrow-right"></span>
        </div>
        <div className="home-slider-prev left-arrow-negative">
            <span className="ti-arrow-left"></span>
        </div>
    </div>
</section>
<section className="section-min section product" id="products">
    <div className="paddinng-y overflow-hidden">
        <div className="row">
            
            <div className="col-md-12">
                <h3 className="section-heading">Featured E-Books</h3>
            </div>
            <div className="col-md-12">
            {loader ? <Loader /> : "" }
                <div className="product-list-sliderd">
                    <ul className="swiper-wrappers product-list product-list-vertical">
            <Slider 
            {...settings}>            
            {books.map(post => (
                    <li className="swiper-slidess text-center"> 
                            <span className="product-list-left pull-left">
                                <Link to={ "ebook/" + post.slug }>
                                    <img alt="product image" className="product-list-primary-img" src={ post.img } /> 
                                </Link>
                            </span> 
                            <Link to={ "ebook/" + post.slug }>
                                <span className="product-list-right pull-left">
                                    <span className="product-list-name h4 black-color">{ post.title } </span>
                                    <span className="product-list-price"><i className="fa fa-inr" aria-hidden="true"></i>{ post.dprice }</span>
                                    <span className="product-list-price sell-p"><del><i className="fa fa-inr" aria-hidden="true"></i>{ post.price }</del></span>
                                    <Rateview val={ post.star } />
                                </span>
                            </Link> 
                            <a onClick={ () => setPurchase(post.slug) } className="btn btn-default add-item" data-image={ slider }>
                                buy now
                            </a> 
                            <div className={ 'col-lg-12 smessage-' + post.slug } />
                        </li>
                    ))}
            </Slider>
                    </ul>
                </div>				
            </div>

        </div>
    </div>
</section>
</>
);
};

export default Home;

