import React from 'react';
import { Helmet } from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import Loader from './components/Loader';
import { Outlet, Link, useNavigate }
from "react-router-dom";
import { useCartContext } from './Context';
const Register = () => {
    const {setRegister, loader} = useCartContext();
    const login = JSON.parse(localStorage.getItem('USER_INFO'));
    var navigate = useNavigate();

    useEffect(() => {
    if (login){
    navigate( - 1);
    }
    }, [])
            return (
                       <>
            <section className="loginSection">
    <div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4 p-4 main-login-area">
            <h5 className="h-right text-right"><Link to="../login">Sign In</Link></h5>
            <h1 className="title title-short">Create Account</h1>
            <form className="product-review-form smooth-submit" method="post" id="register-form">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group mb-4">
                            <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Your Name*</label>
                            <input required="true" id="userType" value="Student" type="hidden" name="user_type" className="form-control" />
                            <input required="true" id="userName" type="text" name="name" className="form-control" />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group mb-4">
                            <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Email*</label>
                            <input required="true" id="userEmail" type="email" name="email" className="form-control" />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group mb-4">
                            <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Mobile*</label>
                            <input required="true" id="userMobile" type="number" name="phone" className="form-control" />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group mb-4">
                            <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Password*(Min 8 characters)</label>
                            <input required="true" id="userPassword" type="password" name="password" className="form-control" />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group mb-4">
                            <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Confirm Password*</label>
                            <input required="true" id="usercPassword" type="password" name="c_password" className="form-control" />
                        </div>
                        <div className="smessage"></div>
                    </div>
                    <p className="col-sm-12 register-legal">
                        By continuing you confirm that you agree to the <a className="kobo-link" href="/in/en/terms/termsofuse"> Terms of Use</a> and confirm that you have read the <a className="kobo-link" href="/in/en/terms/privacypolicy"> Privacy Policy</a>, updated August 15, 2023
                    </p>
                    <div className="col-sm-12 mb-10">
                    {loader ? <Loader /> :
                    <button type="button" onClick={()=>setRegister(document.getElementById('userType').value,document.getElementById('userName').value,document.getElementById('userEmail').value,document.getElementById('userMobile').value,document.getElementById('userPassword').value,document.getElementById('usercPassword').value)} className="width-100 btn btn-dark btn-hover-bg-primary btn-hover-border-primary px-11">Sign Up</button>
            }
    </div>
                </div>
            </form>
            <div id="moreOptions" className="sign-in-options">
                <p aria-hidden="true" className="or-separator">
                    <span>or</span>
                </p>
                <div className="primary-provider">
                    <div className="sign-in-options">
                        <div className="partner-sign-in">
                            <a className="kobo-link partner-option facebook" href="https://www.facebook.com/dialog/oauth?redirect_uri=https%3a%2f%2fauth.kobobooks.com%2fhandleredirection%2ffacebook&amp;state=a58cc0e2-3cdb-462f-8cb0-ea11dda38ee2%2f%3fSignIn&amp;client_id=133723903348827&amp;scope=email&amp;response_type=code" data-track-info="
                               
    { & quot; description & quot; : & quot; Partner - Sign - In & quot; , & quot; section & quot; : & quot; PartnerLink & quot; , & quot; providerName & quot; : & quot; facebook & quot; , & quot; pageName & quot; : & quot; SignIn & quot; }">Sign in with Facebook
                            </a>
                        </div>
                        <div className="partner-sign-in">
                            <a className="kobo-link partner-option google" href="https://accounts.google.com/o/oauth2/v2/auth?state=a58cc0e2-3cdb-462f-8cb0-ea11dda38ee2%2f%3fSignIn&amp;client_id=642155554319-miknav8emp1qcnnv6sltjnk04gshjnin.apps.googleusercontent.com&amp;response_type=code&amp;scope=openid+profile+email&amp;redirect_uri=https%3a%2f%2fauthorize.kobo.com%2fhandleredirection%2fgoogle&amp;access_type=offline&amp;include_granted_scopes=true" data-track-info="
                               
    { & quot; description & quot; : & quot; Partner - Sign - In & quot; , & quot; section & quot; : & quot; PartnerLink & quot; , & quot; providerName & quot; : & quot; google & quot; , & quot; pageName & quot; : & quot; SignIn & quot; }">Sign in with Google
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-4"></div>
    </div>
</section>
</>
);
};

export default Register;
