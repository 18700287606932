const Reducer = (state,action) =>{
      switch (action.type) {
        case "SET_RATING_STAR":
            return {
                ...state,
                rating: action.payload,
            };
        case "loader":
            return {
                ...state,
                loader: action.payload,
            };    

        default:
            return state;
    }
}

export default Reducer;

