import React from 'react';
import { Helmet }
from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import Slider from "react-slick";
import Rating from './components/Rating';
import Rateview from './components/Rateview';
import slider from './../assets/img/index-3-products/p1.jpg';
import slider2 from './../assets/img/index-3-products/p3.jpg';
import { useState, useEffect }
from 'react';
import Loader from './components/Loader';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { useCartContext }
from './Context';
import { Outlet, Link, useParams, useNavigate }
from "react-router-dom";
const Buy = () => {
    const {setPayment, setPromo, loader} = useCartContext();
    const login = JSON.parse(localStorage.getItem('USER_INFO'));
    var navigate = useNavigate();
    let {id} = useParams()
    const [book, setBook] = useState([]);
    useEffect(() => {
        axios.get('https://rank2top.com/api/ebook/' + id)
                .then(response => {
                    setBook(response.data.data);
                })
                .catch(error => {
                    console.error(error);
                });
    }, []);
    useEffect(() => {
    if (!login) {
            navigate('/register');
            }
            }, [])
                    return (
                            <>
<section className="section-buy padding-large">
    <div className="row">
        <div className="col-lg-6">
            <h2 className="section-title highlighted-section-title">Purchase</h2>
            <form id="paymentForm" action="#" method="post">
                <div className="row cart-item-card">
                    <div className="col-lg-12 cart-item-card-top">
                        <div className="row">
                            <div className="col-lg-8">
                                <label>Promo code</label>
                                <div className="input-group">
                                <input id="promo_code" className="form-control" type="text" placeholder="Coupon Code" />
                                    <span className="input-group-btn">
                                        <button onClick={ () => setPromo(document.getElementById('promo_code').value)} className="btn promo-btn btn-default" type="button">
                                            Apply
                                        </button>
                                    </span>
                                </div>
                                <div className='col-lg-12 mt-5 smessage1'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row cart-item-card">
                    <div className="col-lg-12 cart-item-card-top">
                        <div className="row">
                            <div className="row m-0">
                                <div className="col-lg-12 p-2 border-bottom">
                                    <div className="icheck-primary pointer">
                                        <input checked="true" type="radio" id="primary12" name="payu" value="2" className="pointer" />
                                        <label for="primary12" className="pointer">Pay with PayUMoney <img className="imgcc ml-1" src="https://rank2top.com/assets/payumoney.png" /></label>
                                    </div>
                                </div>
                                <input id="ebook_txnid" type="hidden" class="form-control" value=
                                       
                    { Math.floor(Math.random() * 10000000000) } name="txnid" />
                    <input type="hidden" id="ebook_udf6" name="udf6" />
                    <input type="hidden" id="ebook_udf7" name="udf7" />
                                <input type="hidden" id="ebook_udf5" name="udf5" value="BOLT_KIT_PHP7" />
                                <input type="hidden" name="service_provider" value="payu_paisa" size="64" />
                                <input class="form-control" type="hidden" id="ebook_salt" name="salt" placeholder="Merchant Salt" value="wMoRdYbdbU" />
                                <input class="form-control" type="hidden" id="ebook_key" name="key" placeholder="Merchant Key" value="QlIe20OT" />
                                <input type="hidden" id="ebook_udf2" name="udf2" value={ book.id } />
                                <input type="hidden" id="ebook_udf3" name="udf3" value="3" />
                                <input type="hidden" id="ebook_udf4" name="udf4" value="100" />
                                <input type="hidden" id="ebook_surl" name="surl" value="https://rank2top.com/api/ebook_transaction_finish" />
                                <input type="hidden" id="ebook_furl" name="furl" value="https://rank2top.com/api/ebook_transaction_finish" />
                                <input class="form-control" type="hidden" id="ebook_hash" name="hash" placeholder="Hash" value="" />
                                <input type="hidden" value={ Math.round(book.dprice) } id="ebook_amount" name="amount" />
                                <input type="hidden" value={ 'Purchase a E-Book (' + book.title + ')' } id="ebook_pinfo" name="productinfo" />
                                { login ?
                            <input id="ebook_name" value={ login.name } placeholder="Your Name" name="name" type="hidden" class="form-control form-control-rounded" required="true" />
                            :
                            <div></div>
                                }
                                { login ?
                            <input id="ebook_mobile" value={ login.phone } placeholder="Mobile Number" name="mobile" type="hidden" class="form-control form-control-rounded" required="true" />
                            :
                            <div></div>
                                }
                                { login ?
                            <input id="ebook_email" value={ login.email } disabled="true" placeholder="Email Address" name="email" type="hidden" class="form-control form-control-rounded" required="true" />
                            :
                            <div></div>
                                }
                    
                                <div className="col-lg-12 p-2">
                                    <div className="icheck-primary pointer">
                                        <input type="radio" id="primary11" name="payu" value="1" className="pointer" />
                                        <label for="primary11" className="pointer">Pay With Razorpay <img className="imgcc ml-1" src="https://rank2top.com/assets/razorpay.png" /> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="save-payment-information-security-message">
                    <p><i class="fa fa-lock" aria-hidden="true"></i> Your personal information is secure. Learn more about our <button className="btnLink" data-bind="click: showInformationSecurityMeasures">security measures.</button></p>
                </div>
                <div className="btf-5 col-lg-12 mt-xs-5">
                {loader ? <Loader /> : 
                <button onClick={ () => setPayment(document.querySelector('input[name="payu"]:checked').value, document.getElementById('ebook_email').value, document.getElementById('ebook_mobile').value, document.getElementById('ebook_name').value, document.getElementById('ebook_pinfo').value, document.getElementById('ebook_amount').value, document.getElementById('ebook_furl').value, document.getElementById('ebook_surl').value, document.getElementById('ebook_key').value, document.getElementById('ebook_salt').value, document.getElementById('ebook_txnid').value, document.getElementById('ebook_udf5').value, document.getElementById('ebook_udf4').value, document.getElementById('ebook_udf3').value, document.getElementById('ebook_udf2').value, document.getElementById('ebook_udf6').value, document.getElementById('ebook_udf7').value) } type="button" className="button" id="check_finala" className="col-lg-6 mt-5 col-xs-12 mb-3 btn-success btn">
                    Save & Continue
                </button>
                }
                </div>
            </form>
        </div>
        <div className="col-lg-6 mt-xs-5">
            <h2 className="section-title highlighted-section-title">Items</h2>
            <div className="message-box success icon" role="alert">
                <div className="inner-wrapper" id="print-book-message-details">
                    <span className="title">You’re buying an eBook</span>
                    <span className="body">You’re about to purchase digital content rather than a physical book. Read with a Rank2Top eReader or with the free Rank2Top App.</span>
                </div>
            </div>
            <div className="row cart-item-card">
                <div className="col-lg-12 cart-item-card-top">
                    <div className="row">
                        <div className="col-xs-2">
                            <img className="cart-pic" src=












                                 
                    { book.img
                                 }
                    







                                 />
                        </div>
                        <div className="col-xs-7">
                            <h3 className="title-text truncated two-line">







                                
                    { book.title
                                }
                    







                            </h3>
                            <div className="abouts">
                                <p className="author no-border">
                                    <span className="authors product-field contributor-list">
                                        <span className="contributor-name-prefix">by </span>
                                        <span className="visible-contributors">
                                            <span className="mobile-library-tag">Author</span>
                                            <a href="#" className="contributor-name">Rank2Top</a>
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-3 price">
                            ₹







                            
                    { book.dprice }
                    








                        </div>
                    </div>
                </div>
                <div className="col-lg-12 p-0 m-0 cart-item-card-bottom price-summary-container">
                    <div className="row mb-2">
                        <div className="col-lg-9">Purchase subtotal:</div>
                        <div className="col-lg-3 text-right">₹







                            
                    { book.dprice }
                    







                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-9">GST:</div>
                        <div className="col-lg-3 text-right">₹0.00</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-9">Discount:</div>
                        <div className="col-lg-3 text-right discountT">₹0.00</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-9">Total:</div>
                        <div className="col-lg-3 text-right totalT">₹







                            
                    { book.dprice }
                    







                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</>
);
};

export default Buy;




