import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { useCartContext } from './../Context';
import { Container, Radio, Rating } from "./RatingStyles";
const Rate = () => {
    const {setRating, rating} = useCartContext();
    return (
        <Container>
            {[...Array(5)].map((item, index) => {
                const givenRating = index + 1;
                return (
                    <label>
                        <Radio
                            type="radio"
                            name="rate"
                            value={givenRating}
                            onClick={() => {
                                setRating(givenRating);
                            }}
                        />
                        <Rating>
                            <FaStar
                                color={
                                    givenRating < rating || givenRating === rating
                                        ? "000"
                                        : "rgb(192,192,192)"
                                }
                            />
                        </Rating>
                    </label>
                );
            })}
        </Container>
    );
};
 
export default Rate;
