import { FaStar } from "react-icons/fa";
import { Rating } from "./RatingStyles";
const Rateview = (val) => {
    return (
           
            <Rating>
                <FaStar
                    color={
                        val.val > 0
                                ? "000"
                                : "rgb(192,192,192)"
                    }
                    />
                <FaStar
                    color={
                        val.val > 1
                                ? "000"
                                : "rgb(192,192,192)"
                    }
                    />
                <FaStar
                    color={
                        val.val > 2
                                ? "000"
                                : "rgb(192,192,192)"
                    }
                    />
                <FaStar
                    color={
                        val.val > 3
                                ? "000"
                                : "rgb(192,192,192)"
                    }
                    />
                <FaStar
                    color={
                        val.val > 4
                                ? "000"
                                : "rgb(192,192,192)"
                    }
                    />
            </Rating>

            );
};

export default Rateview;



