import React from 'react';
import { Helmet }
from "react-helmet";
import $ from "./../../assets/ebook/js/jquery.js";
import slider from './../../assets/img/index-3-products/p1.jpg';
import slider2 from './../../assets/img/index-3-products/p3.jpg';
import emp from './../../assets/img/covers.svg';
import Loader from './../components/Loader';

import { useState, useEffect }
from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { useCartContext }
from './../Context';
import { Outlet, Link, useParams, useNavigate }
from "react-router-dom";

const Myaccount = () => {
    const login = JSON.parse(localStorage.getItem('USER_INFO'));
    var navigate = useNavigate();
    const [book, setBook] = useState([]);
    const { setPersonal, setPass } = useCartContext();
    const [loader, setLoader] = useState();
    const [file, setFile] = useState()
    function handleChange(event) {
      setFile(event.target.files[0])
    }
    function handleSubmit(event) {
        event.preventDefault()
        const url = 'https://rank2top.com/api/pic_update';
        const formData = new FormData();
        formData.append('pic', file);
        formData.append('id', document.getElementById('puserId').value);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post(url, formData, config).then((response) => {
          setLoader(true)
            if (response.data.type == true) {
                        localStorage.removeItem('USER_INFO');
                        localStorage.setItem("USER_INFO", JSON.stringify(response.data.data[0]));
                        const loginDetail = JSON.parse(localStorage.getItem('USER_INFO'));
                        if (loginDetail) {
                            setLoader(false);
                            $(".profImg").attr('src', loginDetail.pic);
                            $(".smessage").css('display','block');
                            $(".smessage").addClass('msuccess');
                            $(".smessage").html("Saved Successfully!").delay(5000).fadeOut('slow');
                        }
                    } else {
                        setLoader(false);
                        $(".smessage").css('display','block');
                        $(".smessage").addClass('merror');
                        $(".smessage").html("Some Problem!").delay(5000).fadeOut('slow');
                    }
        });
    }
    
    useEffect(() => {
    if (!login) {
        navigate('/register');
        }
        }, [])
                return (
                        <>
<section className="section-breadcu padding-large">
    <div className="row">
        <div className="col-md-12">
            <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to="/">
                    <span>Home</span>
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <span> Account Settings</span>
                </li>
            </ul>
        </div>
    </div>
</section>
<section className="section-description bg-white padding-large pt-5 pb-5">
    
                {loader ? <Loader /> : ""
 }            
    <h4 className="boldText">Sign-in Information</h4> 
    <ul className="nav nav-tabs pt-5">
        <li className="active"><a data-toggle="tab" href="#home">Personal Info</a></li>
        <li><a data-toggle="tab" href="#menu1">Profile Picture</a></li>
        <li><a data-toggle="tab" href="#menu2">Password</a></li>
    </ul>

    <div className="tab-content p-0">
        <div id="home" className="tab-pane pt-5 p-0 fade in active">
            <h5 className="pb-5">Personal Information</h5>
            <form className="product-review-form smooth-submit" method="post" id="personal-form">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group mb-4">
                                    <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Your Name*</label>
                                    { login ?
                                    <input required="" id="puserId" type="hidden" name="user_id" className="form-control" value={login.userId} />
                                    :
                            <input required="" id="puserId" type="hidden" name="user_id" className="form-control" value="" />
                                }
                                { login ?
                                    <input required="" id="puserType" type="hidden" name="user_type" className="form-control" value={login.userType} />
                                    :
                            <input required="" id="puserType" type="hidden" name="user_type" className="form-control" value="" />
                                }
                                { login ?
                                    <input required="true" id="puserName" type="text" name="name" className="form-control" defaultValue={login.name} />
                                    :
                            <input required="true" id="puserName" type="text" name="name" className="form-control" defaultValue="" />
                                }
                                </div>
                            </div>
                            <div className="col-lg-4 mb-10">
                                <button type="button" onClick={()=>setPersonal(document.getElementById('puserId').value,document.getElementById('puserName').value,document.getElementById('puserType').value)} className="width-100 btn btn-dark btn-hover-bg-primary btn-hover-border-primary px-11">Save</button>
                            </div>
                            <div className="col-lg-12 smessage"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div id="menu1" className="tab-pane pt-5 p-0 fade">
            <h5 className="pb-5">Profile Picture</h5>
            <form className="product-review-form smooth-submit" method="post" onSubmit={handleSubmit} id="profile-pic-form">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group mb-4">
                                { login ?
                                    <input required="" id="ppuserId" type="hidden" name="user_id" className="form-control" value={login.userId} />
                                    :
                                    <input required="" id="ppuserId" type="hidden" name="user_id" className="form-control" value="" />
                                }
                                    <input required="true" id="ppuserProfile" onChange={handleChange} type="file" name="name" className="form-control" />
                                
                                </div>
                            </div>
                            <div className="col-lg-4 mb-10">
                                <button type="submit" className="width-100 btn btn-dark btn-hover-bg-primary btn-hover-border-primary px-11">Save</button>
                            </div>
                            <div className="col-lg-12 smessage"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div id="menu2" className="tab-pane pt-5 p-0 fade">
            <h5 className="pb-5">Password Update</h5>
            <form className="product-review-form smooth-submit" method="post" id="profile-password-form">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group mb-4">
                                <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Password*</label>
                                { login ?
                                    <input required="" id="ppuserPhone" type="hidden" name="phone" className="form-control" value={login.phone} />
                                    :
                                    <input required="" id="ppuserPhone" type="hidden" name="phone" className="form-control" value="" />
                                }
                                    <input required="true" id="ppuserPassword" type="password" name="password" className="form-control" />
                                </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-4">
                                    <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Confirm Password*</label>
                                        <input required="true" id="ppuserCPassword" type="password" name="cpassword" className="form-control" />
                                    </div>
                                </div>
                            <div className="col-lg-4 mb-10">
                                <button type="button" onClick={()=>setPass(document.getElementById('ppuserPhone').value,document.getElementById('ppuserPassword').value,document.getElementById('ppuserCPassword').value)} className="width-100 btn btn-dark btn-hover-bg-primary btn-hover-border-primary px-11">Save</button>
                            </div>
                            <div className="col-lg-12 smessage"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
</>
);
};

export default Myaccount;






