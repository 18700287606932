import React from 'react';
import { Helmet } from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import slider from './../assets/img/index-3-products/p1.jpg';
import slider2 from './../assets/img/index-3-products/p3.jpg';
import Rateview from './components/Rateview';
import Loader from './components/Loader';
import { useCartContext }
from './Context';
import axios from 'axios';
import { useState, useEffect }
from 'react';
import ReactDOM from 'react-dom/client';
import { Outlet, Link, useParams }
from "react-router-dom";

const Shopid = () => {
    const {setPurchase} = useCartContext();
    const [loader, setLoader] = useState(true);
    const [books, setBooks] = useState([]);
    const [message, setMessage] = useState([]);
    const [cat, setCat] = useState([]);
    let { id } = useParams()
    useEffect(() => {
    axios.get('https://rank2top.com/api/ebook_list_cat/' + id)
            .then(response => {
            setBooks(response.data.data);
            setMessage(response.data.message);
    setLoader(false);
            })
            .catch(error => {
            console.error(error);
    setLoader(false);
            });
            axios.get('https://rank2top.com/api/ebook_cat_list')
            .then(response => {
            setCat(response.data.data);
    setLoader(false);
            })
            .catch(error => {
            console.error(error);
    setLoader(false);
            });
    }, []);
  return (
          <>
  <section className="section-breadcu padding-large">
    <div className="row">
        <div className="col-md-12">
            <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to="/">
                    <span>Home</span>
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to="/shop">
                    <span> Shop</span>
                    </Link>
                </li>
                <li className="breadcrumb-item active">
                    <span> { message.title }</span>
                </li>
            </ul>
        </div>
    </div>
</section>
<section className="section-min section product" id="products">
    <div className="paddinng-y overflow-hidden">
        <div className="row">
            {loader ? <Loader /> : "" }
            <div className="col-md-3 catSide mt-5 mb-5">
            <ul>
            {cat.map(cats => (
                <li><a className="linkCat" href={ '../shop/' + cats.slug }>{ cats.title }</a></li>
            ))}
            </ul>
            </div>
            <div className="col-md-9">
            
                <div className="row bookSide">         
            {books.map(post => (
                     <div className="col-lg-3 col-xs-6 mb-5 text-center"> 
                            <span className="product-list-left pull-left">
                                <Link to={ "../ebook/" + post.slug }>
                                    <img alt="product image" className="product-list-primary-img" src={ post.img } /> 
                                </Link>
                            </span> 
                            <Link to={ "../ebook/" + post.slug }>
                                <span className="product-list-right pull-left">
                                    <span className="product-list-name h4 black-color">{ post.title } </span>
                                    <span className="product-list-price"><i className="fa fa-inr" aria-hidden="true"></i>{ post.dprice }</span>
                                    <span className="product-list-price sell-p"><del><i className="fa fa-inr" aria-hidden="true"></i>{ post.price }</del></span>
                                    <Rateview val={ post.star } />
                                </span>
                            </Link> 
                            <a onClick={ () => setPurchase(post.slug) } className="btn btn-default add-item" data-image={ slider }>
                                buy now
                            </a> 
                            <div className={ 'col-lg-12 smessage-' + post.slug } />
                        </div>
                    ))}
                </div>				
            </div>

        </div>
    </div>
</section>
  </>
            );
};

export default Shopid;

