import React from 'react';
import { Helmet } from "react-helmet";
import $ from "./../../assets/ebook/js/jquery.js";
import logo from './../../assets/img/logo.png';
import slider from './../../assets/img/index-3-products/p1.jpg';
import slider2 from './../../assets/img/index-3-products/p3.jpg';
import profileicon from './../../assets/img/icon-profile.svg';
import Loader from './../components/Loader';
import './../../assets/css/bootstrap-light.css';
import './../../assets/css/theme-light.css';
import './../../assets/css/App.css';
import './../../assets/css/font-awesome.min.css';
import './../../assets/css/responsive.css';
import './../../assets/js/custom.js';
import Home from './../Home';
import { useState }
from 'react';
import ReactDOM from 'react-dom/client';
import { Outlet, Link }
from "react-router-dom";
import { useCartContext } from './../Context';
const Weblayout = () => {
    const login = JSON.parse(localStorage.getItem('USER_INFO'));
    const { setLogout } = useCartContext();
    return (
            <>
        
<nav className="navbar navbar-fixed-top" id="js-nav">
    <div className="containers row">
        <div className="navbar-header col-xs-3">
            <Link className="navbar-brand" to="/">
                <img src=
    { logo }
    alt="" />
            </Link>
            
            <div className="headRight">
                <ul>
                    <li>
                        <button className="navbar-toggle" data-target="#myNavbar" data-toggle="collapse" type="button">
                            <span className="icon-bar"></span> 
                            <span className="icon-bar"></span> 
                            <span className="icon-bar"></span>
                        </button>
                    </li>
                    <li>
                    { login ?
                        <div className="dropdown-lgo-small">
                            <ul className="navbar-nav align-items-center right-nav-link">
                                <li className="nav-item">
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" data-toggle="dropdown" href="#" aria-expanded="false">
                                    { login ?    
    <span className="user-profile">
                                        
                                            { login.pic == '' ? 
                            <img src={ profileicon } className="profImg img-circle i-avatar" alt={ login.name } />
                            :
                                    <img src={ login.pic } className="profImg img-circle i-avatar" alt={ login.name } />
                                    }
                                    
                                        </span>
                                        :
            <p></p>
        }
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li className="dropdown-item"><Link to="mybook"><i className="icon-envelope mr-2"></i> My Books</Link></li>
                                        <li className="dropdown-divider"></li>
                                        <li className="dropdown-item"><Link to="myaccount"><i className="icon-envelope mr-2"></i> My Account</Link></li>
                                         <li className="dropdown-divider"></li>
                                         <li className="dropdown-item"><a onClick={ ()=> setLogout() }><i className="icon-envelope mr-2"></i> Logout</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        :
            <span></span>
        }
                    </li>
                </ul>
            </div>
        </div>
        <div className="searchBar  col-xs-6">
                        <form action="search" method="get">
                            <div className="input-group">
                            <input name="find" className="form-control " type="text" placeholder="Seach Here..." /> 
                                <span className="input-group-btn">
                                    <button className="btn btn-default" type="submit"><span className="ti-search"></span></button>
                                </span>
                            </div>
                        </form>
                    </div>
        <div className="collapse navbar-collapse  col-xs-3" id="myNavbar">
            <ul className="nav navbar-nav">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/shop">Shop</Link></li>
                { login ? "" : <li><Link to="/login" className="loginMenu">SignIn</Link></li> }
            </ul>
            { login ?
            <div className="dropdown-lgo dropdown-lgo-large">
            <ul className="navbar-nav align-items-center right-nav-link">
                <li className="nav-item">
                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" data-toggle="dropdown" href="#" aria-expanded="false">
                        <span className="user-profile">
                            { login.pic == '' ? 
                            <img src={ profileicon } className="profImg img-circle i-avatar" alt={ login.name } />
                            :
                                    <img src={ login.pic } className="profImg img-circle i-avatar" alt={ login.name } />
                                    }
                        </span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li className="dropdown-item"><Link to="mybook"><i className="icon-envelope mr-2"></i> My Books</Link></li>
                        <li className="dropdown-item"><Link to="myaccount"><i className="icon-envelope mr-2"></i> My Account</Link></li>
                        <li className="dropdown-divider"></li>
                        <li className="dropdown-item"><a onClick={ ()=> setLogout() }><i className="icon-envelope mr-2"></i> Logout</a></li>
                    </ul>
                </li>
            </ul>
        </div>
            :
            <span></span>
        }
        </div>
        
    
    
    </div>
</nav>
<div className="searchBar extrbar  d-none d-xs-block col-xs-12">
                        <form action="search" method="get">
                            <div className="input-group">
                            <input name="find" className="form-control " type="text" placeholder="Seach Here..." /> 
                                <span className="input-group-btn">
                                    <button className="btn btn-default" type="submit"><span className="ti-search"></span></button>
                                </span>
                            </div>
                        </form>
                    </div>
<Outlet />

<div className="section section-min">
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-12">
                    <div className="f_logo">
                        <a href="#">
                            <img src=
    { logo } alt="" />
                        </a>
                        <p>
                            Sed ut perspiciatis unde omnis iste natus error sit best shop for you
                            voluptatem.Sed ut perspiciatis unde omnis iste natus errorsit.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 info_menu">
                    <h4>INFORMATIONS</h4>
                    <ul>
                        <li><a href="#">  Delivery information </a></li>
                        <li><a href="#">   Privacy Policy </a></li>
                        <li><a href="#">   Terms &amp; Conditions </a></li>
                        <li><a href="#">   Return &amp; Exchange </a></li>
                    </ul>
                    <ul>
                        <li><a href="#">  Free Shipping </a></li>
                        <li><a href="#">   Order Status </a></li>
                        <li><a href="#">   Gift Cards</a></li>
                        <li><a href="#">   International</a></li>
                    </ul>
                </div>
                <div className="col-md-4  col-sm-12">
                    <div className="footer-newsletter">
                        <div className="center">
                            <h4>stay with us</h4>
                            <form action="#" method="post">
                                <div className="input-group">
                                    <input className="form-control " type="text" placeholder="e-mail" /> 
                                    <span className="input-group-btn">
                                        <button className="btn btn-default" type="button"><span className="ti-arrow-right"></span></button>
                                    </span>
                                </div>
                            </form>
                            <div className="social">
                                <ul>
                                    <li className="fndus">Find us here:</li>
                                    <li><a href="http://facebook.com/" target="_blank"><span className="ti-facebook"></span></a></li>
                                    <li><a href="https://twitter.com/" target="_blank"><span className="ti-twitter-alt"></span></a></li>
                                    <li><a href="http://linkedin.com/" target="_blank"><span className="ti-linkedin"></span></a></li>
                                    <li><a href="https://vimeo.com/" target="_blank"><span className="ti-vimeo-alt"></span></a></li>
                                    <li><a href="http://youtube.com/" target="_blank"><span className="ti-youtube"></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix col-md-12 col-sm-12">
                    <hr/>
                </div>
                <div className="col-md-12 col-sm-12">
                    <div className="footer-copyright">
                        <p>© 2022 Rank2Top - All Rights Reserved.</p>
                    </div>
                </div>  
            </div>
        </div>
    </footer>
</div>
</>
);
}
export default Weblayout;