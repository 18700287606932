import React from 'react';
import { Helmet }
from "react-helmet";
import back from './../../assets/img/back.png';
import screen from './../../assets/img/fullscreen.png';
import nextIcon from './../../assets/img/icon/iconss_0011_Layer-11.png';
import prevIcon from './../../assets/img/icon/iconss_0012_Layer-10.png';
import fullIcon from './../../assets/img/icon/iconss_0014_Layer-8.png';
import homeIcon from './../../assets/img/icon/iconss_0015_Layer-7.png';
import zoomIn from './../../assets/img/icon/iconss_0019_Layer-3.png';
import zoomOut from './../../assets/img/icon/iconss_0020_Layer-2.png';
import { useState, useEffect, useRef }
from 'react';
import $ from "./../../assets/ebook/js/jquery.js";
import axios from 'axios';
import Loader from './../components/Loader';
import './../../assets/ebook/js/jquery.fullscreen.js';
import './../../assets/ebook/css/custom.css';
import HTMLFlipBook from 'react-pageflip';
import ReactDOM from 'react-dom/client';
import Parser from 'html-react-parser';
import { useCartContext }
from './../Context';
import { Outlet, Link, useParams, useNavigate }
from "react-router-dom";
        const Page = React.forwardRef((props, ref) => {
        return (
                <div className="page" ref={ref}>
    <div className="page-content">
        <div className="page-image">{props.children}</div>
        <div className="page-text"></div>
        <div className="page-footer">{props.number}</div>
    </div>
</div>
                );
        });
        const Readebook = () => {
const [page, setPage] = useState(0);
        const [height, setHeight] = useState(200);
        const [totalPage, setTotalPage] = useState(0);
        const [loader, setLoader] = useState(true);
        const login = JSON.parse(localStorage.getItem('USER_INFO'));
        var navigate = useNavigate();
        let {id} = useParams()
        const [book1, setBook1] = useState([]);
        const [book, setBook] = useState([]);
        const [detail, setDetail] = useState([]);
        const tableRef = useRef(null);
        var flipBook = {};
        const nextButtonClick = () => {
if (!document.fullscreenElement) {
flipBook.pageFlip().flipNext();
} else {
flipBook.pageFlip().flipNext();
}

};
        const prevButtonClick = () => {
if (!document.fullscreenElement) {
flipBook.pageFlip().flipPrev();
} else {
flipBook.pageFlip().flipPrev();
}
};
const zoominClick = (event) => {
    $(".demo-book .page-image").width(
        $(".demo-book .page-image").width() * 1.2
    );

    $(".demo-book .page-image").height(
        $(".demo-book .page-image").height() * 1.2
    );
};
const zoomoutClick = (event) => {
    $(".demo-book .page-image").width(
        $(".demo-book .page-image").width() * 0.8
    );

    $(".demo-book .page-image").height(
        $(".demo-book .page-image").height() * 0.8
    );
};
        const onPage = (e) => {
setPage(e.data);
        };
        const gotoClick = (pageNumber) => {
if (page < pageNumber){
for (let ik = page; ik < pageNumber; ik += 2) {
flipBook.pageFlip().flipNext();
}
} else{
for (let ik = page; ik > pageNumber; ik -= 2) {
flipBook.pageFlip().flipPrev();
}
}
};
        const homeClick = () => {
for (let ik = page; ik > 0; ik -= 2) {
flipBook.pageFlip().flipPrev();
}
};
        const enterFullscreen = () => {
const elem = tableRef.current;
        if (elem.requestFullscreen) {
elem.requestFullscreen();
} else if (elem.mozRequestFullScreen) { // Firefox
elem.mozRequestFullScreen();
} else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
elem.webkitRequestFullscreen();
}
};
        const exitFullscreen = () => {
if (document.exitFullscreen) {
document.exitFullscreen();
} else if (document.mozCancelFullScreen) {
document.mozCancelFullScreen();
} else if (document.webkitExitFullscreen) {
document.webkitExitFullscreen();
}
};
        const fullscreenClick = () => {
if (!document.fullscreenElement) {
enterFullscreen();
} else {
exitFullscreen();
}
};
        useEffect(() => {
        axios.get('https://rank2top.com/api/ebook/' + id)
                .then(response => {
                setBook1(response.data.data);
                        axios.post("https://rank2top.com/api/ebook_detail", {id: response.data.data.id, email: login.email, phone: login.phone})
                        .then(response1 => {
                        setBook(response1.data.data);
                                setDetail(response1.data.detail);
                                setLoader(false);
                        })
                        .catch(error => {
                        console.error(error);
                                setLoader(false);
                        });
                })
                .catch(error => {
                console.error(error);
                });
        }, []);
        useEffect(() => {
        if (!login) {
        navigate('/register');
        }
        }, [])

$(window).resize(function(){
  var h = document.documentElement.clientHeight * 0.90;
  var w = h * 0.73 * 2;
  $(".demo-book").css("min-height", h);
  $(".--simple").css("height", h);
  $(".demo-book").css("width", w);
  $(".--simple").css("width", w);
})

        if (!loader){
setTimeout(function () {
$(".abouts").css("padding", "5%");
        $(".abouts").css("background", "#fff");
        }, 100)
        }

return (
        <>
<div  id="fb7" ref={tableRef}>
    <div className="centered">
        <div id="fb7-footer">
            <ul className="fb7-menu" id="fb7-center">
                <li className="mrr-1">
                    <Link title="Go Back to Home" to="../mybook" className="close-video-im pointer">
                    <img src=
                         { back } className="w-100z" />
                    </Link>
                </li>
                <li className="mrr-1 left-b">
                    <a title="Go To Previous Page" className="fb7-nav-arrow prev" style=
            {{display: page == 0 ? "none" : "block" }} type="button" onClick={() => prevButtonClick() }>
                        <img src={ prevIcon } className="w-100z" />
                    </a>
                </li>
                <li className="mrr-1 right-b">
                    <a title="Go To Next Page" className="fb7-nav-arrow next" type="button" onClick={() => nextButtonClick()}
                       ><img src=
                          { nextIcon } className="w-100z" />
                    </a>
                </li>
                <li className="mrr-1">
                    <a title="Fullscreen" onClick={() => fullscreenClick() } className="fullscreen-video-im fb7-fullscreen pointer">
                        <img src=
                             { fullIcon } className="w-100z" />
                    </a>
                </li>
                <li className="mrr-1">
                    <a title="Go To Home" onClick={() => homeClick() } className="fullscreen-video-im fb7-fullscreen pointer">
                        <img src=
                             { homeIcon } className="w-100z" />
                    </a>
                </li>
            </ul>
            <div className="fb7-menu" id="fb7-right">
                <ul>                                        
                    <li className="fb7-goto">
                        <label for="fb7-page-number" id="fb7-label-page-number"></label>
                        <input onFocus={
        (e) => {
var target = $(e.currentTarget);
        target.data('current', target.val());
        target.val('')
}
    }
                               onBlur={
        (e) => {
var target = $(e.currentTarget);
        var old = target.data('current');
        if (target.val() == '') {
target.val(old);
}
}
    }
                               placeholder={(page + 1) + "-" + (page + 2)} type="text" id="fb7-page-number" />
                        <button onClick={() => gotoClick(document.getElementById('fb7-page-number').value) } type="button">Go</button>
                    </li>

                </ul>
            </div>  
        </div>
    </div>
    <HTMLFlipBook
        width={740}
        height={1024}
        size="stretch"
        minWidth={380}
        maxWidth={1480}
        minHeight={512}
        maxHeight={2048}
        disableFlipByClick={false}
        autoSize={false}
        maxShadowOpacity={1}
        showCover={false}
        mobileScrollSupport={true}
        onFlip={onPage}
        className="demo-book"
        ref={(el) => (flipBook = el)}
        >
        {loader ? <Loader /> :
        <Page number="1" className="demoPage"> 
            <div  className="abouts">
                <h1 class="text-black"><strong>{detail.title}</strong></h1>
                <div class="description"><span dangerouslySetInnerHTML={{__html: detail.description}}></span></div>
            </div>
        </Page>
        }
        {book.map(vals => (
                <Page number={vals.i + 1 } className="demoPage"> 
            <img className="imgBook" src={vals.imgr} />

        </Page>
                ))
        }
    </HTMLFlipBook>  

</div>


</>
);
};

export default Readebook;









